import Vue from 'vue'
import Router from "vue-router"

Vue.use(Router)

let routes = [
  {
    path: '*',
    component: () => import('../pages/Index.vue')
  }
]

const router = new Router({
  routes,
  // base: '/sketch/',
})

export default router