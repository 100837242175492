import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./styles/reset.scss"
import {
  Button,
  Select,
  Option,
  Form,
  FormItem,
  Input,
  Message,
  MessageBox,
  Card,
  Tag,
  Upload
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Card)
Vue.use(Tag)
Vue.use(Upload)

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

new Vue({
  render: h => h(App),
  router
}).$mount('#app')